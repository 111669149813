// @use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;
html {
  font-size: 0.625rem;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 1.4rem;
  margin: 0;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  //   "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;
}

.app-loading {
  padding: 50px;
  color: #666;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.text-center {
  text-align: center !important;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.ml-10 {
  margin-left: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.pt-4 {
  padding-top: 4px;
}

.pr-20 {
  padding-right: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pr {
  position: relative;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.w-25 {
  width: 25%;
}

.w-70p {
  width: 70%;
}

.w-100 {
  width: 100% !important;
}

.word-break {
  word-break: break-all;
  padding-right: 10px;
}

p {
  margin: 0;
  padding: 0;
  a {
    color: #0073bb;
  }
}

.m-h-50 {
  min-height: 50px;
}

.m-w-45 {
  max-width: 45%;
}

.m-w-65 {
  max-width: 65%;
}

.word-break {
  word-break: break-all;
}

.border-left-c {
  border-left: 1px solid #eaeded !important;
  padding-left: 2rem !important;
}

.bt-title {
  font-size: 18px !important;
}

.bt-sa {
  color: #888;
}

.bt-tag {
  font-size: 12px;
  display: inline-block;
  border-radius: 20px;
  background-color: #444;
  color: #fff;
  padding: 1px 10px;
  margin: 5px 10px 5px 0;
  text-transform: capitalize;
}

.custom-auth__box__container {
  min-height: 90vh; // height of the browser viewport
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
}

.custom-auth__box {
  padding-top: 25px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.char-capital {
  text-transform: capitalize;
  padding-right: 10px;
}

.orange {
  color: #ec7211;
}

.poc-app {
  .poc-header {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    .logo {
      font-size: 1.2em;
      font-weight: bold;
      color: #fff;
      flex: 1;
    }
    .top-user {
      color: #fff;
    }
    .logout {
      color: #fff;
      cursor: pointer;
      padding: 5px 10px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .search-action {
    flex: 1;
    width: 100%;
    .poc-search {
      max-width: 648px;
    }
    .date-range {
      padding-left: 10px;
      min-width: 350px;
      padding-right: 20px;
    }
  }
  .review-radio-title {
    font-size: 1.2em;
    padding: 5px 0 5px 0;
  }
  .arch-img {
    width: 100%;
  }
  .assets-arch {
    width: 70%;
  }
  .add-new {
    margin-top: 10px;
  }
  .attachment {
    .upload {
      width: 230px;
      .file-name {
        cursor: pointer;
      }
      .file-size {
        font-size: 12px;
        color: #666;
        margin-top: -5px;
      }
    }
    .select {
      width: 320px;
    }
    .remove {
      margin-left: 45px;
    }
  }
  .bp-filter {
    .bp-search {
      flex: 1;
      min-width: 600px;
      max-width: 780px;
    }
    .bp-select {
      flex: 1;
      padding-left: 20px;
      padding-right: 20px;
      max-width: 450px;
      // min-width: 200px;
    }
  }
}

.swal2-container {
  z-index: 9999 !important;
}

.swal2-popup {
  font-size: 1.6rem !important;
}

.not-found {
  padding: 50px;
  text-align: center;
  h1 {
    font-size: 18px;
    padding: 10px;
    font-weight: normal;
  }
}

// Hide Time Input Temp
div[class^="_date-and-time-wrapper_"] {
  display: none !important;
}

// PoC Review Page Style
.gsal-review-page {
  display: flex;
  .gsal-step-nav {
    display: inline-block;
    width: 200px;
    min-width: 200px;
    margin-right: calc(2 * 40px);
    padding-top: 4px;
    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
      li {
        border-bottom: 1px solid #d5dbdb;
        margin-top: 5px;
        margin-bottom: 11px;
        padding-bottom: 16px;
      }
      small {
        font-size: 12px;
        line-height: 16px;
        color: #687078;
        display: block;
        margin-bottom: 4px;
      }
      .step-name {
        > span {
          font-weight: 700;
          line-height: 22px;
          color: #16191f;
          &.link {
            color: #0073bb;
            font-weight: normal;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .gsal-review-content {
    min-width: 0;
    -ms-word-break: break-all;
    word-break: break-word;
    width: 100%;
  }
}

.gsal-radio-group {
  display: flex !important;
  > div {
    margin-top: 0.5rem;
    margin-right: 2rem;
  }
}

.poc-withdraw-button {
  position: absolute;
  right: 80px;
  margin-top: -35px;
}

.arch-img-item {
  .remove-icon {
    display: none;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  &:hover {
    .remove-icon {
      display: block;
    }
  }
}
