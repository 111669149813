// @use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

#b {
    // accommodate the space for the fixed header
    padding-top: 38px;
    &.poc-top-banner {
        padding-top: 78px;
        // set top in gamma env
        div[class^="_drawer-content_"] {
            top: 80px !important;
        }
    }
}

#h {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // corresponds to the z-index in Mezzanine
    z-index: 1000;
    margin: 0;
    padding: 0 30px;
    // background: awsui.$color-background-home-header;
    background-color: #232f3e;
    &.top-banner {
        top: 40px;
    }
}

.top-banner-content {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
    padding: 0 30px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    right: 0;
    background-color: #ec7211;
    color: #fff;
}
